import React, { useState } from "react"
import PrimaryLayout from "@src/layouts/primary"
import { UserContext } from "@src/context/user-context"
import PageHelmet from "@src/components/page-helmet"
import { normalizeTitle } from "@src/utils/page-data"
import ResponsiveSection from "@src/components/container-responsive-section"
import Form, { FormActions } from "@src/components/form"
import FormFieldEmailAddress from "@src/components/form-field-email"
import Button from "@src/components/core-button"
import Link from "@src/components/core-link"
import Spinner from "@src/components/core-spinner"
const AccountVerifyResendPage = () => {
  const [success, setSuccess] = useState()
  const [verifying, setVerifying] = useState(false)
  const [formMessage, setFormMessage] = useState()
  return (
    <PrimaryLayout>
      <PageHelmet title={normalizeTitle("Verification Resend")} />
      <UserContext.Consumer>
        {({ verifyResend }) => {
          const handleSubmit = ({ email }) => {
            verifyResend(email)
              .then(() => {
                setVerifying(false)
                setSuccess(true)
                setFormMessage()
              })
              .catch(error => {
                setVerifying(false)
                setSuccess(false)
                setFormMessage(error)
              })
          }
          return success ? (
            <ResponsiveSection variant="card">
              <h1>New verification code has been sent!</h1>
              <p>Please check your email for further instructions.</p>
              <p>
                <Link to="/account/verification/">
                  Click here to verify your account
                </Link>
              </p>
            </ResponsiveSection>
          ) : (
            <ResponsiveSection variant="card">
              <h1>Resend verification code</h1>
              <Form
                name="user-verification-resend"
                onSubmit={handleSubmit}
                variant="blue"
              >
                <FormFieldEmailAddress
                  id="email"
                  name="email"
                  placeholder="Enter email address to get a new code"
                  label="Email address"
                  validation={{
                    required:
                      "We'll need your email address to send a new code",
                  }}
                />
                <FormActions>
                  {formMessage && <p className="form-error">{formMessage}</p>}
                  {verifying ? (
                    <Button variant="rounded" disabled>
                      Sending <Spinner color="white" size="sm" />
                    </Button>
                  ) : (
                    <Button variant="rounded" type="submit">
                      Resend Verification
                    </Button>
                  )}

                  <p>
                    Already have a verification code?{" "}
                    <Link to="/account/verification/">Enter it now</Link>
                  </p>
                </FormActions>
              </Form>
            </ResponsiveSection>
          )
        }}
      </UserContext.Consumer>
    </PrimaryLayout>
  )
}
export default AccountVerifyResendPage
